/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}
@font-face {
	font-family: uzi;
	src: url('./assets/font/ethnocentric\ rg\ it.otf');
	font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #000000 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: 100%;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}
/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}
nav.navbar a.navbar-brand {
     height: fit-content;
     width: fit-content;
     margin-top: -10px;
     padding-bottom: 10px;
     float: left;
    
}
a.navbar-brand img {
  width: 50%;
  scale: 0.7;
  height: 70px;
  object-fit: fill;
  
 }
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  position: relative;
  background-color: rgba(0, 0, 0, 0.7); /* Make sure the positioning context is set for the pseudo-element */
}

.banner::before {
  content: "";
  z-index: -10;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('./assets/img/banner-bg.png');
  /* Add a black overlay with some transparency */
  
  filter: blur(10px);
}

 
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 5vw;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
  font-family: uzi;
}
.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
 
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  

  animation: updown 3s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}
/********** our work *************/
.work-sec {
  position: relative;
  background-color: #000;
  padding: 23.33vw 0 12.5vh 0;
  
}
 
.work-sec .int-1 .container-1{
  margin-left:800px ;
  left: 0;
  margin-top: 100px;


}
 .work-sec .title-work{
   text-align: center ;
    
     
   position: relative;
   font-size: 5vw;
   margin-top: -14.58vh;   
   }
   .work-sec2{

     background-color: black;
     padding: 23.33vw 0 12.5vh 0;
     width: 100%;
     height: fit-content;
    
   }
   .work-sec2 .title-work2{
   text-align:center;
   position: relative;
   margin-top: -20%;
   font-size: 4vw;
  
   }
   .work-sec3{

    background-color: black;
    padding: 160px 0 0px 0;
    height: fit-content;


   }
   .title-work3{

    text-align:center;
    position: relative;
    margin-top: -20.58vh; 
    font-size: 4vw;

   }
   .img4{
    margin-top: 200px;
    margin-bottom: 200px;
    width: 300px;
    border-radius: 30px;
    height: 300px;
   }
   .img5{
    margin-top: 200px;
    margin-bottom: 200px;
    margin-left: 30px;
    border-radius: 30px;
    width: 300px;
    height: 300px;
   }
   .img6{
    margin-top: 200px;
    margin-bottom: 200px;
    width: 300px;
    border-radius: 30px;
    margin-left: 30px;
    height: 300px;
   }
   .img6.active{
    animation: picslide1 1.5s ease-out forwards;

   }
   .img4.active{
    animation: picslide1 1s ease-out forwards;

   }
   .img5.active{

    animation: picslide1 1.2s ease-out forwards;

   }

   
   @keyframes fades {
    0% {
      margin-top: 40px;
     font-size: 30px;
     color: rgb(252, 143, 0);
 
    }
    50% {
      margin-top: 40px;
      font-size: 50px;
      color: rgb(85, 49, 1);
     
      opacity: 0;
    } 
    100% {
       
      opacity: 1;
    }
  } 
  @keyframes fas {
    
    0% {
      opacity: 1;
       
    }
    100% {
      opacity: 1;
       
    }
  }
   .title-work2.active {
    /* Set the animation name, duration, and iteration count */
    animation: fades 2s ease-out forwards;
  }
   
 
    .title-work3.active{
     
    animation: rightos 1s ease-out forwards;
   }
   .active {
    animation: slide-in 1s ease-out forwards;
  }

 
 .title-work23{
  margin-top: 500px;
  font-size: 80px;
 }
.title-work24{
   font-size: 4vw;
 }
 .earthpic {
  width: 100%; /* Genişliği yüzde olarak ayarla */
  max-width: 1600px; /* Maksimum genişlik belirle */
  height: auto; /* Yüksekliği oranları koruyarak otomatik ayarla */
 
  position: relative;
  
}
 
.rockpic {
  width: 100%; /* Genişliği yüzde olarak ayarla */
  max-width: 1600px; /* Maksimum genişlik belirle */
  height: auto; /* Yüksekliği oranları koruyarak otomatik ayarla */
 
  position: relative;
  transform: scale(0.3); /* Scale özelliğini transform ile kullan */
  transform-origin: top; /* Ölçek orijinini yukarıda ayarla */
}

 .rockpic.hidden{
 opacity: 1;
 
 }
 .workuzi-pic{
  border-radius: 20px;
 }
  
 
  .golden-word {
    color: rgb(231, 135, 10); /* ya da sarının başka bir tonu */
    
  }
  .vid-1{
    margin-top: 50px;
    margin-left: 700px;
    
    scale: 1   ;
 
  }
  .vid-1 div{
    width: 20%;
    height: 100%;
  }
  .title-work5{
    text-align: center;
    margin-top: 200px;
    font-size:   9vw;
  }
  .title-work6{
    text-align: center;
   color: #666;
   font-weight: 100;
    font-size: 2vw;
   font-style: italic;
  }
  .title-work7{
    text-align: left;
    margin-top: -250px;
    text-justify: auto;
    color: #dacec9  ;
    font-weight: lighter;
    font-size: 2vw;
    margin-bottom: 250px;
  }
  .responsive-section {
    width: 20vw;
    padding-top: 2vw;
    height: fit-content;
    display: inline-block;
    vertical-align: top;
    margin-right: 50px;
  }
  .unit-cont{
    padding: 20px;
  
   }
   .unit-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
   }
   .unit-card{
   
    border-radius: 10px;
    overflow: hidden;
   }
   .unit-info{
    text-align: center;
    padding: 10px;
    height: 35vh;
   }
   .unit-title{
  
    text-align: center;
    font-size: 2vw;
    margin-bottom: 1.5vh;
  
   }
   .unit-paragraph{
    margin-top: 0vh;
    color: gray;
    font-size: 1vw;
    font-style: italic;
   }
   .unit-pic{
   
    width: 100%;
    height: 50vh;
    object-fit: cover;
   }

.sponsors-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 20px;
}
.sponsor-title{
  text-align: center;
  font-size: clamp(5rem, 5vw, 6rem);
  margin-top: 100px;
  margin-bottom: 100px;
}
.sponsor {
    position: relative;
    width: 200px;
    height: 200px;
    margin: 10px;
    background-color: #000;
    box-shadow: 0 4px 8px rgba(0, 0, 0,);
    transition: transform 0.3s;
}

.sponsor:hover {
    transform: scale(1.1);
}

.sponsor img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sponsor .role {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
    color: white;
    font-weight: bold;
    text-align: center;
}

.sponsor.diamond .role {
    color: #b9f2ff;
}

.sponsor.gold .role {
    color: #ffd700;
}



  @keyframes picslide1 {
    from {
    
      opacity: 0;
      transform: translateX(-10vw  );
      
    }
    to {
      
      opacity: 1;
      animation-delay: 2;
     
      
    }
  }

  @keyframes picslide1 {
    from {
    
      opacity: 0;
      transform: translateX(-10vw  );
      
    }
    to {
      
      opacity: 1;
      animation-delay: 2;
     
      
    }
  }
  
  @keyframes rightos {
    from {
    
      opacity: 0;
      
    }
    to {
      
      opacity: 1;
      animation-delay: 2;
      transform: translateX(-10vw  );
      
    }
  }
  
  @keyframes slide-in {
    from {
     
      opacity: 0;
    }
    to {
      
      opacity: 1;
    }
  }

  
  .main-title-units{
    margin-bottom: 0px;
    font-size: 20vw;
     

  }
 
  
   
 

  @keyframes fadeIn {
    from {
      opacity: 1;
      color: #f86300;
      font-size: 1em;
    }
    to {
      opacity: 0;
 
      color: #ffffff;
      font-size: 1.5em;
    }
  }

  .main-title-units.active{
    
    animation: fadeIn 1s  linear forwards;
  }

 
  .img7{
    margin-top: 200px;
    margin-bottom: 200px;
    width: 300px;
    border-radius: 30px;
    height: 300px;
   }

   
 
   
  
   .hidden {
    visibility: hidden;
  }

  @media (max-width:1400px){
    
   .vid-1{
    scale: 0.9;
    margin-left: 500px;
   }
   

  }
  @media (max-width:1200px){
    
    .vid-1{
     scale: 0.5;
     margin-left: 500px;
    }
    .unit-info{
      height: 15vh;
    }
 
   }
   @media (max-width:1000px){
    
    .vid-1{
     scale: 0.6;
     margin-left: 200px;
    }
    .title-work7{
      width: 300px;
    }
    .unit-info{
      height: 15vh;
    }
 
   }
   @media (max-width:760px){
    
    .vid-1{
     scale: 0.4;
     margin-left: 100px;
    }
    .title-work7{
      width: 300px;
    }
    .title-work5{
      margin-left: -100px;
    }
    
 
   }
   @media (max-width:450px){
    
    .vid-1{
     scale: 0.45 ;
     margin-left: -150px;
    }
    .unit-info{
      height: 15vh;
    }
    .title-work5{
      font-size:70px;
      margin-left:-30px;
    }
    .work-sec .title-work{
      font-size: 65px;
     
    }
   .work-sec2 .title-work2{
      font-size: 30px;
    }
    .unit-grid{
      grid-template-columns: repeat(1, 1fr);
      
    }
    
   
    /* Define the keyframes for the fade animation */
    
    .unit-info{
      padding-top: 150px;
    }
    .unit-title{
      font-size: 25px;
    }
    .unit-paragraph{
      font-size: 20px;
    }
    .unit-pic{
      margin-top: 350px;
    }
    .main-title-units{
      position: absolute;
      font-size: 100px;
      margin-left: 25px;
    }
    .title-work24{
      height: 300px;
       padding-top: 150px;
      font-size: 50px;
     }
     .unit-cont{
      padding-bottom: 0px;
     }
     .title-work3{
      
      font-size: 40px;
      padding-bottom: 100px;
      margin-left: 20px;
     }
     .title-work7{
      font-size: 30px;
      margin-top: -400px;
      padding-bottom: 30px;
     }
     .title-work6{
      font-size: 20px;
      padding-bottom: 40px;
     }
     .banner div{

      margin-top: -40px;
     }
     .banner h1{
      font-size: 40px;
     }
    .banner img{
      margin-top: 50px;
    }
   
   
  
 
   }
   @media (max-width:360px){
    
    .vid-1{
     scale: 0.30 ;
     margin-left: -70px;
    }
    
  }
  .main-title-units.active{
      
    animation: fas 2s ease-out forwards;
  }
  
 /**** ourTeam****/
 /* Ourteam.css */
.ourteam {
  background-color: black;
  color: white;
  padding: 20px;
}

.ourteam-title {
  text-align: center;
  font-size: 36px;
  margin-bottom: 40px;
}

.ourteam-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.ourteam-card {
  border: 2px solid orange;
  border-radius: 10px;
  overflow: hidden;
}

.ourteam-pic {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.ourteam-info {
  text-align: center;
  padding: 10px;
}

.ourteam-name {
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
}

.ourteam-role {
  font-size: 18px;
  font-style: italic;
}

/************ Projects Css ************/
.project {
  padding: 80px 0;
  position: relative;
  background-color: black;
}
.project h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}
.project p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #c77408 -5.91%, #100e16 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
 
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}
 
.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #f38303 -5.91%, #000000 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 0%;
  right: 0;
  z-index: -4;
}

/************ Projects Css ************/
.contact {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 60px 0 200px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/
.footer {
 height: 50px;
 
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.footer p{
  
  text-align: center;
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
 
